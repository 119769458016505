html {
  /* 1rem = 10px */
  font-size: 62.5%;
}

body{
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: $font_inter; 
  font-weight: 400;
  color: $white;
}

.container{
  margin: 0 auto;
  max-width: 70rem;
  padding: 0 3rem;
}

.page-content{
  padding: 5rem 0;
  background: linear-gradient(180deg, #4CB2B6 0%, #3FD7B2 100%);

  a{
    color: $white;
  }

  p{
    margin-bottom: 1.6rem; 
  }
}

.page-form{
  padding: 5rem 0;
  background: linear-gradient(180deg, #4CB2B6 0%, #3FD7B2 100%);
  min-height: 100vh;
}

.feedback {
  max-width: 30rem;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  svg{
    margin-bottom: 3rem;
  }

  &--ko{
    svg{
     
      path{
        fill: #78445A;
      }
    }
  }

  p{
    width: 100%;
    margin-bottom: 1.6rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

}

.options-bx{
  margin-bottom: 2rem;

  &__it{
    position: relative;
    margin-bottom: 1rem;
  }

  &__list{
    background-color: white;
    color: #000;
    width: calc(100% - 40px);
    top: 100%;
    margin-top: -18px;
    padding: 2rem;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    display: none;

    &.js-toggle{
      display: block;
    }

    li{
      padding: 1rem 1.5rem;
      @media (max-width: $md){
        padding: 1rem 0;
      }
      &.js-group-selector{
        cursor: pointer;
      }
      &.active{
        font-weight: 700;
      } 
    }
  }

  &__tit {
    background: #FFFFFF;
    border-radius: 20px;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    min-height: 4.6rem;
    display: flex;
    padding: 0 3.5rem;
    align-items: center;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: $md){
      padding: 0 3.5rem 0 2rem; 
    }

    &.active{
      font-weight: 700;
      display: inline-flex;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    &::after{
      content: '';
      width: 1.2rem;
      height: 1.4rem;
      background-image: url('../imgs/icon-toggle.svg');
      background-repeat: no-repeat;
      background-position: center;
      right: 2.5rem;
      position: absolute;
      top: 1.6rem;
    }

    &.js-toggle{
      &::after{
        transform: rotate(180deg);
      }
    }
  }
}

.page-return{
  margin-bottom: 2.8rem;
}

.tit{
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 2rem;
}