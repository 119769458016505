@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

$font_inter: 'Inter', sans-serif;

//colors
$black: #000;
$white: #fff;
$primary: #396D78;

$lg: 991.98px;
$md: 767.98px;