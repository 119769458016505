.header{
  padding: 2rem 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo{
    img{
      max-width: 26rem;
      width: 100%;
    }
  }
}