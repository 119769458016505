.btn{
  font-family: $font_inter;
  min-height: 4.6rem;
  background: $primary;
  border-radius: 2rem; 
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: center;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 0.1rem solid $primary;
  cursor: pointer;

  &:hover{
    color: $primary;
    background-color: $white;
  }

  &--full{
    width: 100%;
  }
}

.btn-icon{
  width: 3.2rem;
  height: 3.2rem;
  background: $primary; 
  background-image: url('../imgs/icon-arrow.svg');
  border-radius: 100%;
  border: 0;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
}