/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  /* 1rem = 10px */
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #fff;
}

.container {
  margin: 0 auto;
  max-width: 70rem;
  padding: 0 3rem;
}

.page-content {
  padding: 5rem 0;
  background: linear-gradient(180deg, #4CB2B6 0%, #3FD7B2 100%);
}
.page-content a {
  color: #fff;
}
.page-content p {
  margin-bottom: 1.6rem;
}

.page-form {
  padding: 5rem 0;
  background: linear-gradient(180deg, #4CB2B6 0%, #3FD7B2 100%);
  min-height: 100vh;
}

.feedback {
  max-width: 30rem;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.feedback svg {
  margin-bottom: 3rem;
}
.feedback--ko svg path {
  fill: #78445A;
}
.feedback p {
  width: 100%;
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.options-bx {
  margin-bottom: 2rem;
}
.options-bx__it {
  position: relative;
  margin-bottom: 1rem;
}
.options-bx__list {
  background-color: white;
  color: #000;
  width: calc(100% - 40px);
  top: 100%;
  margin-top: -18px;
  padding: 2rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  display: none;
}
.options-bx__list.js-toggle {
  display: block;
}
.options-bx__list li {
  padding: 1rem 1.5rem;
}
@media (max-width: 767.98px) {
  .options-bx__list li {
    padding: 1rem 0;
  }
}
.options-bx__list li.js-group-selector {
  cursor: pointer;
}
.options-bx__list li.active {
  font-weight: 700;
}
.options-bx__tit {
  background: #FFFFFF;
  border-radius: 20px;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  min-height: 4.6rem;
  display: flex;
  padding: 0 3.5rem;
  align-items: center;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}
@media (max-width: 767.98px) {
  .options-bx__tit {
    padding: 0 3.5rem 0 2rem;
  }
}
.options-bx__tit.active {
  font-weight: 700;
  display: inline-flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.options-bx__tit::after {
  content: "";
  width: 1.2rem;
  height: 1.4rem;
  background-image: url("../imgs/icon-toggle.svg");
  background-repeat: no-repeat;
  background-position: center;
  right: 2.5rem;
  position: absolute;
  top: 1.6rem;
}
.options-bx__tit.js-toggle::after {
  transform: rotate(180deg);
}

.page-return {
  margin-bottom: 2.8rem;
}

.tit {
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 2rem;
}

.header {
  padding: 2rem 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .logo img {
  max-width: 26rem;
  width: 100%;
}

.btn {
  font-family: "Inter", sans-serif;
  min-height: 4.6rem;
  background: #396D78;
  border-radius: 2rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 0.1rem solid #396D78;
  cursor: pointer;
}
.btn:hover {
  color: #396D78;
  background-color: #fff;
}
.btn--full {
  width: 100%;
}

.btn-icon {
  width: 3.2rem;
  height: 3.2rem;
  background: #396D78;
  background-image: url("../imgs/icon-arrow.svg");
  border-radius: 100%;
  border: 0;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
}

form p {
  margin-bottom: 1.5rem;
}

.form-group {
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
}

.form-label {
  margin-bottom: 1.2rem;
}

.form-control {
  width: 100%;
  border-radius: 2rem;
  background-color: #fff;
  min-height: 4.6rem;
  padding: 0.5rem 3rem;
  font-family: "Inter", sans-serif;
  border: 0;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #000;
  margin-bottom: 1rem;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  display: block;
}
.form-control::placeholder {
  color: #000;
  border: 0;
  font-size: 1.6rem;
  line-height: 1.9rem;
}
.form-control:active, .form-control:focus, .form-control:focus-within, .form-control:visited, .form-control:focus-visible, .form-control:target {
  outline: none;
}

input[type=date] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 4.6rem;
  min-width: calc(100% - 16px);
  height: 4.6rem;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

input::-webkit-date-and-time-value {
  text-align: left;
  padding-left: 2rem;
  padding-right: 2rem;
}

.custom-checkbox {
  font-family: "Inter", sans-serif;
  display: block;
  margin-bottom: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.6rem;
  text-align: left;
  color: #000;
  margin-bottom: 9px;
  position: relative;
  margin: 1.5rem 0 1.5rem;
  min-height: 3.2rem;
}
.custom-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  cursor: pointer;
  left: 7px;
  position: absolute;
  top: 2px;
  opacity: 0;
}
.custom-checkbox input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 0.5rem;
  left: 0.8rem;
  width: 1.6rem;
  height: 1.6rem;
  background-color: transparent;
  border-radius: 100%;
  border: 0;
  background-color: #396D78;
}
.custom-checkbox input label {
  position: relative;
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: #396D78;
}
.custom-checkbox input label a {
  color: #000;
  font-weight: 700;
}
.custom-checkbox label {
  padding-left: 4.5rem;
}
.custom-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  box-shadow: none;
  padding: 0px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 1.2rem;
  padding: 0;
  top: -3px;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 100%;
  background: #fff;
  border: 0;
  position: absolute;
  left: 0;
}
.custom-checkbox label a {
  color: #000;
  font-weight: 700;
}

.alert .list-unstyled {
  background-color: rgba(255, 255, 255, 0.8);
  color: #396D78;
  padding: 1rem 2rem;
  margin: 0 0 2rem;
  border-radius: 2rem;
}