form{
  p{
    margin-bottom: 1.5rem
  }
}

.form-group{
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
}

.form-label{
  margin-bottom: 1.2rem;
}

.form-control{
  width: 100%;
  border-radius: 2rem;
  background-color: $white;
  min-height: 4.6rem;
  padding: 0.5rem 3rem;
  font-family: $font_inter;
  border: 0;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: $black;
  margin-bottom: 1rem;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box; 
  display: block;

  &::placeholder{
    color: $black;
    border: 0;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  &:active,
  &:focus,
  &:focus-within,
  &:visited,
  &:focus-visible,
  &:target{
    outline: none;
  }
}

input[type="date"]{
     -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 4.6rem; 
    min-width: calc(100% - 16px);
    height: 4.6rem;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

input::-webkit-date-and-time-value {
  text-align: left;
  padding-left: 2rem;
  padding-right: 2rem;
}

.custom-checkbox {
  font-family: $font_inter;
  display: block;
  margin-bottom: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; 
  font-size: 1.2rem;
  line-height: 1.6rem;
  text-align: left;
  color: $black;
  margin-bottom: 9px;
  position: relative;
  margin: 1.5rem 0 1.5rem;
  min-height: 3.2rem;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    cursor: pointer;
    left: 7px;
    position: absolute;
    top: 2px;
    opacity: 0;

    &:checked{
      & + label{
        &::after{
          content: '';
          display: block;
          position: absolute;
          top: 0.5rem;
          left: 0.8rem;
          width: 1.6rem;
          height: 1.6rem;
          background-color: transparent;
          border-radius: 100%;
          border: 0;
          background-color: $primary;
        }
      }
    }

    label{
      position: relative;
      cursor: pointer;
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      color: $primary;

      a{
        color: $black;
        font-weight: 700; 

      }
    }
  }

  label{
    padding-left: 4.5rem;
    &:before {
      content:'';
      -webkit-appearance: none;
      background-color: transparent;
      box-shadow: none;
      padding: 0px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 1.2rem;
      padding: 0;
      top: -3px;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 100%;
      background: $white;
      border: 0;
      position: absolute;
      left: 0;
    }
    a{
      color: $black;
      font-weight: 700;
    }
  }
}

.alert{
  .list-unstyled {
    background-color: rgba(255,255,255,0.8);
    color: $primary;
    padding: 1rem 2rem;
    margin: 0 0 2rem;
    border-radius: 2rem;
  }
}